/** @format */

@font-face {
  font-family: 'Etrusco';
  src: url('/public/fonts/EtruscoNowCompressed-Regular.otf') format('opentype');
  font-weight: normal; /* Regular font weight */
  font-style: normal;
}
@font-face {
  font-family: 'Etrusco';
  src: url('/public//fonts/EtruscoNowCondensed-Bold.otf') format('opentype');
  font-weight: bold; /* Bold font weight */
  font-style: normal;
}
@font-face {
  font-family: 'BeVietnam';
  src: url('/public/fonts//BeVietnamPro-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BeVietnamLT';
  src: url('/public/fonts/BeVietnamPro-Light.ttf') format('opentype');
  font-weight: 'light';
  font-style: normal;
}
@font-face {
  font-family: 'AwesomeSerif';
  src: url('/public/fonts/PTSerif-Regular.ttf') format('opentype');
  font-weight: 'normal';
  font-style: normal;
}
/* Global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Etrusco', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif 'Awesome-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
